import React from "react"
// import Title from "../title"
import ReactPlayer from "react-player"

import { Container, Row, Col, Card } from "react-bootstrap"

import * as styles from "../../styles/components/Resources/videos.module.scss"
import { useState } from "react"

const videoFiles = [
  {
    url: "https://youtu.be/gAPYdQ5SKiY",
    desc: "Kids dream of being heroes! At Kids & Teens, We employ them! | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/qoLvhitf9rU",
    desc: "Your child's health & safety is our Priority | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/_g4-2eSqERU",
    desc: "It's Flu season…Call your pediatrician's office today | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/R1AihMKEMUU",
    desc: "Getting Children's Vaccinations during the Covid-19 Pandemic || Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/Yo7aYZtCSOY",
    desc: "What to expect at a Meet & Greet at Kids & Teens Medical Group | Dr. Janesri De Silva",
  },
  {
    url: "https://youtu.be/T0FTVD2slhU",
    desc: "Schedule an Appointment with Kids & Teens Today | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/fjlc4OyLbpE",
    desc: "Expecting your First Child? Get to know the First Steps | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/t9sFik15PWw",
    desc: "Dr.Janesri Desilva explains 4 simple steps of scheduling an appointment @ Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/L7kZeTyFfBw",
    desc: "VLOG Post - Covid19 Vaccine Update - Dr Janesri De Silva | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/JJbSelc1q3s",
    desc: "Kids Developmental Milestones - 24 Months - 02 Years | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/SiTjiDWj1ms",
    desc: "Get the best pediatric medical care for your child | Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/RyPUn02Yju8",
    desc: "Telehealth Service by Kids & Teens Medical Group",
  },
  {
    url: "https://youtu.be/DoSkizybxVc",
    desc: "Switch to Kids & Teens Medical Group Today | The Number 01 Pediatric Medical Group in Los Angeles",
  },
]

export default function Videos() {
  //load more button state
  //   const [noOfElement, setNoOfElement] = useState(2)
  //   const lodeMore = () => {
  //     setNoOfElement(noOfElement + noOfElement)
  //   }

  // const slice = videoFiles.slice(0, noOfElement)
  return (
    <div>
      {/* <Title title="Videos" /> */}
      <Container className="pb-5">
        <Row xs={1} md={2} className="g-5 ">
          {/* {slice.map((file, i) => ( */}
          {videoFiles.map((file, i) => (
            <Col>
              <Card className={`${styles.card} mx-auto`}>
                <Card.Header className={`${styles.header}`}>
                  {file.title}
                </Card.Header>
                <Card.Body>
                  <div className="ratio ratio-16x9">
                    <ReactPlayer
                      url={file.url}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                </Card.Body>
                <Card.Footer className={`${styles.footer} py-5`}>
                  {file.desc}
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <button className="btn btn-primary w-100" onClick={() => lodeMore()}>
          Load More
        </button> */}
      </Container>
    </div>
  )
}
