import * as React from "react"
import Layout from "../components/layout"
import Videos from "../components/Resources/videos"
import Seo from "../components/seo"

const VideosPage = () => (
    <Layout>
        <Seo title="videos" description="Check in for your virtual visit with Kids &amp; Teens Medical Group. Pediatrics serving patients in the Greater Los Angeles area . Call our office at 818-244-9595 or schedule an appointment online." />
        <Videos />
    </Layout>
)

export default VideosPage
